const messages = {
  titleSteps: [
    'Datos personales',
    '¿Dónde enviaremos tus moons cuando esten listos?',
    'Método de Pago'
  ],
  name: 'Nombre',
  movil: 'Teléfono',
  email: 'Correo electrónico',
  street: 'Calle',
  interiorNumber: 'Número',
  zipCode: 'Código postal',
  colony: 'Colonia',
  department: 'Departamento',
  for: 'para',
  township: 'Municipio',
  neighborhood: 'Barrio',
  state: 'Estado',
  city: 'Ciudad',
  reference: 'Indicaciones especiales para llegar a tu domicilio',
  card: 'Número de tarjeta',
  cardName: 'Titular de la tarjeta',
  month: 'Mes (MM)',
  year: 'Año (YY)',
  cvc: 'CVC',
  textInst: {
    MX: 'Esta tarjeta no acepta diferir a meses',
    CO: 'Esta tarjeta no acepta diferir a cuotas'
  }
}

export default messages
