/* eslint-disable react/jsx-handler-names */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, createRef } from 'react'
import arrow from '../../Assets/images/svg/arrow_down.svg'
import styles from './GenericDropdown.module.css'

/**
 * Installments Component
 * @returns {React.Component} Installments .
 */
class GenericDropdown extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    const { defaultValue } = this.props
    this.state = {
      openDropDown: false,
      currentTopic: defaultValue
    }
    this.refImg = createRef(null)

  }

  OpenDropDown = () => {
    const { openDropDown } = this.state
    const open = !openDropDown
    this.refImg.current.style.transform = `rotate(${open ? 0 : 180}deg)`
    this.setState({ openDropDown: open })
  }

  /**
   * Change value selector
   * @param {object} item .
   * @param {object} title .
   * @returns {React.Component} Reference .
   */
  changeValue = (item) => {
    this.setState({ currentTopic: item })
    this.props.onChange(item.value)
  }

  /**
   * Render.
   * @param {string} id .
   * @returns {void} .
   */
  render() {
    const { openDropDown, currentTopic } = this.state
    const { items, error } = this.props
    let errorSpan = null
    if (error) {
      errorSpan = <span className={styles.ErrorMessage}>{error}</span>
    }
    const listItems = items.map(item => {
      const idText = item.value
      const name = item.value
      if (currentTopic.id !== item.id) {
        return (
          <li
            key={item.id}
            id={idText}
            name={name}
            onClick={() => { this.changeValue(item) }}
            className={styles.Items}
            role="menuitem"
          >
            {item.text}
          </li>
        )
      }
      return null
    })
    const selector = (
      <div className={styles.ThemesMobileContainer}>
        <div
          className={styles.TopicSelector}
          onClick={() => this.OpenDropDown()}
          role="button"
          tabIndex="0"
        >
          <div className={styles.TextTema}>
            {currentTopic.text}
            <img
              ref={this.refImg}
              alt="flecha"
              src={arrow}
              style={{ transform: `rotate(${openDropDown ? 0 : 180}deg)` }}
            />
          </div>
          {openDropDown && (
            <ul>
              {listItems}
            </ul>
          )}
        </div>
      </div>
    )

    return (
      <div className={styles.DropdownContainer}>
        {selector}
        {errorSpan}
      </div>
    )
  }
}

export default GenericDropdown
