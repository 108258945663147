const data = {
  paymentMethods: [
    {
      id: 1,
      value: '',
      text: 'Selecciona método de pago'
    },
    {
      id: 2,
      value: 'card',
      text: 'Tarjeta de débito / crédito'
    },
    {
      id: 3,
      value: 'oxxo',
      text: 'Pago en efectivo - OXXO',
    },
    {
      id: 4,
      value: 'spei',
      text: 'Transferencia bancaria - SPEI'
    },
    {
      id: 5,
      value: 'pse',
      text: 'Pagos Seguros en Línea - PSE'
    },
    {
      id: 6,
      value: 'efecty',
      text: 'Efecty'
    },
    {
      id: 7,
      value: 'baloto',
      text: 'Baloto'
    }
  ],
  imgPayments: [
    {
      id: 1,
      type: 'visa',
      enable: true
    },
    {
      id: 2,
      type: 'mastercard',
      enable: true
    },
    {
      id: 3,
      type: 'americanExpress',
      enable: true
    },
    {
      id: 4,
      type: 'spei',
      enable: true
    },
    {
      id: 5,
      type: 'oxxo',
      enable: true
    },
    {
      id: 6,
      type: 'pse',
      enable: true
    },
    {
      id: 7,
      type: 'unionPay',
      enable: false,
    },
    {
      id: 8,
      type: 'applePay',
      enable: false,
      express: false
    },
    {
      id: 9,
      type: 'paypal',
      enable: false,
      express: false
    },
    {
      id: 10,
      type: 'efecty',
      enable: true,
      express: false
    },
    {
      id: 11,
      type: 'baloto',
      enable: true,
      express: false
    },
  ],
  titleExpress: 'EXPRESS CHECKOUT'
}

export default data
