/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, createRef } from 'react'
import styles from './Coupon.module.css'
import messages from './Coupon.messages'
import arrow from '../../Assets/images/svg/left-arrow.svg'
import arrowBlue from '../../Assets/images/svg/arrow-blue.svg'
import { addClass, removeClass } from '../../Utils/utils'
import Services from '../../Apis/Back4AppServices'
import FormGroup from '../FormGroup/FormGroup'

/**
 * Coupon Component
 * @returns {void} .
 */
class Coupon extends Component {
  /**
   * Coupon constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {
      coupon: '',
      error: false
    }

    this.refAddCoupon = createRef(null)
    this.refInput = createRef(null)
  }

  componentDidMount = () => {
    const { couponData, urlCoupon } = this.props
    if ((couponData && couponData.code) || urlCoupon) {
      this.showInputCoupon()
    }

    if (urlCoupon) this.applyCouponURL()
  }

  applyCouponURL = () => {
    const { urlCoupon } = this.props
    this.setState({ coupon: urlCoupon })
    this.changeCoupon(urlCoupon)
  }

  showInputCoupon = () => {
    const divAddCoupon = this.refAddCoupon.current
    const divInput = this.refInput.current

    addClass(divAddCoupon, styles.Hide)
    removeClass(divInput, styles.Hide)
  }

  changeCoupon = async (urlCoupon) => {
    const { couponData, nameProduct } = this.props
    let { coupon } = this.state

    if (!coupon && urlCoupon) coupon = urlCoupon

    let responseCoupon = await Services.getCoupon(coupon, nameProduct)

    if (!responseCoupon) {
      responseCoupon = await Services.getReferralCoupon(coupon, nameProduct)
    }

    if (responseCoupon) {
      couponData && couponData.setCoupon && couponData.setCoupon({ ...responseCoupon })
      this.setState({ coupon: '', error: false })
    } else {
      this.setState({ error: messages.errorCoupon })
      couponData && couponData.setCoupon && couponData.setCoupon()
    }
  }

  deleteCoupon = () => {
    const { couponData } = this.props
    couponData && couponData.setCoupon && couponData.setCoupon()
  }

  /**
   * Coupon render
   * @returns {void} .
   */
  render() {
    const { couponData } = this.props
    const { coupon, error } = this.state
    const code = couponData && couponData.currentCoupon && couponData.currentCoupon.Code
    return (
      <div className={styles.CouponContainer}>
        <div
          ref={this.refAddCoupon}
          role="button"
          tabIndex={0}
          className={styles.AddCupon}
          onClick={() => this.showInputCoupon()}
        >
          {messages.addCoupon}
          <img
            className={styles.ArrowRight}
            alt="cupon"
            src={arrow}
          />
        </div>
        <div
          ref={this.refInput}
          className={[styles.InputCoupon, styles.Hide].join(' ')}
        >
          <hr className={styles.Separator} />
          <div className={styles.Wrapper}>
            <FormGroup
              styleForm={styles.Form}
              idInput="coupon"
              typeInput="text"
              error={error}
              textLabel={messages.coupon}
              defaultValue={coupon}
              onChange={(event) => this.setState({ coupon: event.currentTarget.value })}
              onKeyPress={(event) => {
                if (event.which === 13) this.changeCoupon()
              }}
            />
            <div
              role="button"
              tabIndex={0}
              className={styles.Rectangle}
              onClick={() => this.changeCoupon()}
            >
              <img
                className={styles.ArrowBlue}
                alt="cupon"
                src={arrowBlue}
              />
            </div>
          </div>
          {code && (
            <div className={styles.TagCoupon}>
              <div className={styles.TextCoupon}>
                {code}
                <div
                  tabIndex="0"
                  role="button"
                  className={styles.DeleteCoupon}
                  onClick={() => { this.deleteCoupon() }}
                >
                  {messages.close}
                </div>
              </div>
            </div>
          )}
          <hr className={styles.Separator} />
        </div>
        <hr className={styles.SeparatorFooter} />
      </div>
    )
  }
}

export default Coupon
