/* eslint-disable react/jsx-no-literals */
import React from 'react'
import style from './Footer.module.css'
import messages from './Footer.messages'
import saveSend from '../../Assets/images/svg/saveSend.svg'
import { chooseCountry } from '../../Utils/utils'

/**
 * Footer Component
 * @returns {void} .
 */
const Footer = ({ country }) => {
  const urlTerm = messages.urlTerm[country]
  const urlPrivacy = messages.urlPrivacy[country]
  const countryCode = chooseCountry(country)

  return (
    <div className={style.FooterContainer}>
      <div className={style.LinksContainer}>
        <p className={style.ItemStripe}>
          {messages.by}
          <b className={style.Bold}>{messages.stripe}</b>
        </p>
        <div className={style.ItemLove}>
          <p>{messages.textLove[countryCode]}</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
