/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable require-await */

/**
 * Sent to CRM.
 * @param {string} data .
 * @param {function} handleSubmit .
 * @param {function} handleError .
 * @returns {void} .
 */
const sentToCRM = async (data, handleSubmit, handleError) => {
  const { address, patient } = data

  let obj = {
    deal: {}
  }

  if (patient) {
    obj = {
      ...obj,
      customerId: patient.CustomerId || patient.customerId,
    }
  } else {
    obj = {
      phone: data.number,
      email: data.email,
      deal: {
        Patient_Name: data.name,
        Phone: data.number,
        Email: data.email,
        Source: 'Organic',
        Country_Ops: process.env.REACT_APP_COUNTRY
      }
    }
  }

  if (address && address.street) {
    if (address.street) obj.deal.Street = address.street

    if (address.interiorNumber) obj.deal.Address_Number = address.interiorNumber

    if (address.reference) obj.deal.References = address.reference

    if (address.colony) obj.deal.Neighborhood = address.colony

    if (address.city) obj.deal.City = address.city

    if (address.state) obj.deal.State = address.state

    if (address.zipCode) obj.deal.Zip_Code = address.zipCode

    if (data.country) obj.deal.Shipping_Country = data.country
  } else if (patient) {
    handleSubmit()
    return
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(obj)
  }

  const BlackboxUrl = process.env.REACT_APP_BLACKBOX_URL
  const response = await fetch(BlackboxUrl, requestOptions)
  const dataResponse = await response.json()

  if (response.ok) {
    if (dataResponse && dataResponse.Deal && dataResponse.Deal.code === 'SUCCESS') {
      handleSubmit(dataResponse)
    } else {
      handleError(dataResponse)
    }
  } else {
    handleError(dataResponse)
  }
}

export default sentToCRM
