import React from 'react'
import {
  Switch,
  Route,
  BrowserRouter
} from 'react-router-dom'
import Checkout from './Components/Checkout/Checkout'
import CheckoutFromPaymentId from './Components/CheckoutFromQuerys/CheckoutFromPaymentId'
import CheckoutFromPaymentLink from './Components/CheckoutFromQuerys/CheckoutFromPaymentLink'
import CheckoutOrganic from './Components/CheckoutFromQuerys/CheckoutOrganic'
import PSEResponse from './Components/PSEResponse/PSEResponse'
import Shell from './Components/Shell/Shell'

const productsKeyName = ['organic-moons-kit', 'organic-advance']

/**
 * Main app
 * @returns {void} .
 */
const App = () => (

  <BrowserRouter>
    <Switch>
      <Route
        exact
        path="/checkout/paymentLink/:paymentKeyName/:salesAgentId?"
        component={() => (
          <Shell bannerDisabled>
            <CheckoutFromPaymentLink
              isAdvance={false}
              showPersonalData={false}
              showCoupon={false}
              extraProductsAllowed={false}
              showSend
              selector={1}
            />
          </Shell>
        )}
      />
    </Switch>
  </BrowserRouter>
)

export default App
