import React from 'react'
import styles from './Address.module.css'
import FormGroup from '../FormGroup/FormGroup'

/**
 * PersonalData Component
 * @param {*} props - .
 * @param {boolean}   errorName - true if error in name input
 * @param {boolean}   errorEmail - true if error in email input
 * @param {boolean}   errorNumber - true if error in number input
 * @param {String}    nameTextLabel - text for label name input
 * @param {String}    emailTextLabel - text for email label input
 * @param {String}    numberTextLabel - text for number label input
 * @returns {void} .
 */
const Address = (props) => {
  const {
    errorStreet,
    errorInteriorNumber,
    errorZipCode,
    errorColony,
    errorState,
    errorCity,
    errorReference,
    streetTextLabel,
    interiorNumberTextLabel,
    zipCodeTextLabel,
    colonyTextLabel,
    stateTextLabel,
    cityTextLabel,
    referenceTextLabel,
    onChangeValue,
    address,
    country
  } = props

  return (
    <div className={styles.AddressContainer}>
      <FormGroup
        idInput="street"
        error={errorStreet}
        typeInput="text"
        textLabel={streetTextLabel}
        defaultValue={address.street}
        onChange={(event) => onChangeValue('address', 'street', event.target.value)}
      />
      <div className={styles.RowGroup2}>
        <div className={styles.Col1}>
          <FormGroup
            idInput="interiorNumber"
            error={errorInteriorNumber}
            typeInput="text"
            textLabel={interiorNumberTextLabel}
            defaultValue={address.interiorNumber}
            onChange={(event) => onChangeValue('address', 'interiorNumber', event.target.value)}
          />
        </div>
        <div className={styles.Col2}>
          <FormGroup
            idInput="zipCode"
            error={errorZipCode}
            typeInput="text"
            textLabel={zipCodeTextLabel}
            defaultValue={address.zipCode}
            onChange={(event) => onChangeValue('address', 'zipCode', event.target.value)}
          />
        </div>
      </div>
      <FormGroup
        idInput="colony"
        error={errorColony}
        typeInput="text"
        textLabel={colonyTextLabel}
        defaultValue={address.colony}
        onChange={(event) => onChangeValue('address', 'colony', event.target.value)}
      />
      <FormGroup
        idInput="state"
        error={errorState}
        typeInput="text"
        textLabel={stateTextLabel}
        defaultValue={address.state}
        onChange={(event) => onChangeValue('address', 'state', event.target.value)}
      />
      <FormGroup
        idInput="city"
        error={errorCity}
        typeInput="text"
        textLabel={cityTextLabel}
        defaultValue={address.city}
        onChange={(event) => onChangeValue('address', 'city', event.target.value)}
      />
      {country !== 'Colombia' && (
        <FormGroup
          idInput="reference"
          error={errorReference}
          typeInput="text"
          textLabel={referenceTextLabel}
          defaultValue={address.reference}
          onChange={(event) => onChangeValue('address', 'reference', event.target.value)}
        />
      )}
    </div>
  )
}

export default Address
