/* eslint-disable max-statements */
import React, { useEffect, useRef } from 'react'
import styles from './CardData.module.css'
import FormGroup from '../FormGroup/FormGroup'
import GenericDropdown from '../GenericDropdown/GenericDropdown'
import messages from './CardData.messages'
import { chooseCountry } from '../../Utils/utils'
// import mcAffe from '../../Assets/images/png/mcAffe.png'

/**
 * PersonalData Component
 * @param {*} props - .
 * @param {boolean}   errorName - true if error in name input
 * @param {boolean}   errorEmail - true if error in email input
 * @param {boolean}   errorNumber - true if error in number input
 * @param {String}    nameTextLabel - text for label name input
 * @param {String}    emailTextLabel - text for email label input
 * @param {String}    numberTextLabel - text for number label input
 * @returns {void} .
 */
const CardData = (props) => {
  const {
    errorDniType,
    errorDniValue,
    errorCard,
    errorCardName,
    errorMonth,
    errorYear,
    errorCvc,
    cardTextLabel,
    cardNameTextLabel,
    monthTextLabel,
    yearTextLabel,
    cvcTextLabel,
    onChangeValue,
    payment,
    country,
    showInstallmentsText,
    currentProduct
  } = props

  const mcAffeRef = useRef(null)

  useEffect(() => {
    const interval = setInterval(() => {
      const tabIndex = mcAffeRef.current.getAttribute('tabIndex')
      if (tabIndex === '0') {
        mcAffeRef.current.setAttribute('tabIndex', '-1')
      } else if (tabIndex === '-1') {
        clearInterval(interval)
      }
    }, 100)
  }, [])

  if (country === 'Colombia') {
    window.Mercadopago.getIdentificationTypes()
  }
  let mercadoPagoForm = null
  let docTypeComponent = null
  let docTypeNumberComponent = null
  const fontStyle = {
    fontSize: '0.8em'
  }

  /**
   * function to change the value of the dniType variable
   * @param {*} value The new valio of the dniType
   * @returns {Void}.
   */
  const onChangeDniType = (value) => {
    onChangeValue('payment', 'dniType', value)
  }
  if (country === 'Colombia') {
    mercadoPagoForm = (
      <div
        id="pay"
        style={{ display: 'none' }}
      >
        <input
          type="hidden"
          id="cardNumber"
          data-checkout="cardNumber"
          autoComplete="off"
        />
        <input
          type="hidden"
          id="cardholderName"
          data-checkout="cardholderName"
        />
        <input
          type="hidden"
          id="cardExpirationMonth"
          data-checkout="cardExpirationMonth"
          autoComplete="off"
        />
        <input
          type="hidden"
          id="cardExpirationYear"
          data-checkout="cardExpirationYear"
          autoComplete="off"
        />
        <input
          type="hidden"
          id="securityCode"
          data-checkout="securityCode"
          autoComplete="off"
        />
        <select
          type="hidden"
          id="docType"
          data-checkout="docType"
        />
        <input
          type="hidden"
          id="docNumber"
          data-checkout="docNumber"
        />
      </div>
    )
    docTypeComponent = (
      <div className={styles.DniSelector}>
        <GenericDropdown
          idSelect="dniType"
          items={messages.dniData}
          defaultValue={messages.dniData[0]}
          error={errorDniType}
          onChange={onChangeDniType}
        />
      </div>
    )
    docTypeNumberComponent = (
      <div className={styles.Col1}>
        <FormGroup
          style={fontStyle}
          idInput="dniText"
          typeInput="text"
          error={errorDniValue}
          textLabel="Número de documento"
          defaultValue={payment.dniValue}
          onChange={(event) => onChangeValue('payment', 'dniValue', event.target.value)}
        />
      </div>
    )
  }
  if (country === 'México') {
    mercadoPagoForm = (
      <div
        id="pay"
        style={{ display: 'none' }}
      >
        <input
          type="hidden"
          id="cardNumber"
          data-checkout="cardNumber"
          autoComplete="off"
        />
        <input
          type="hidden"
          id="cardholderName"
          data-checkout="cardholderName"
        />
        <input
          type="hidden"
          id="cardExpirationMonth"
          data-checkout="cardExpirationMonth"
          autoComplete="off"
        />
        <input
          type="hidden"
          id="cardExpirationYear"
          data-checkout="cardExpirationYear"
          autoComplete="off"
        />
        <input
          type="hidden"
          id="securityCode"
          data-checkout="securityCode"
          autoComplete="off"
        />
      </div>
    )
  }

  const countryCode = chooseCountry(country)
  const textInstallments = messages.textInstallments[countryCode] || ''

  return (
    <div className={styles.CardDataContainer}>
      <div className={styles.RowGroup3}>
        {showInstallmentsText && <p className={styles.InstallmentsText}>{textInstallments}</p>}
        {docTypeComponent}
        {docTypeNumberComponent}
        <div className={styles.Col1}>
          <FormGroup
            style={fontStyle}
            idInput="cardName"
            error={errorCardName}
            typeInput="text"
            index={1}
            textLabel={cardNameTextLabel}
            defaultValue={payment.cardName}
            onChange={(event) => onChangeValue('payment', 'cardName', event.target.value)}
          />
        </div>
        <div className={styles.Col1}>
          <FormGroup
            style={fontStyle}
            idInput="cardID"
            error={errorCard}
            typeInput="text"
            index={2}
            textLabel={cardTextLabel}
            defaultValue={payment.card}
            onChange={(event) => onChangeValue('payment', 'card', event.target.value)}
          />
          <div
            ref={mcAffeRef}
            className={errorCard ? `mfes-trustmark ${styles.McAffeError}` : `mfes-trustmark ${styles.McAffe}`}
            data-type="102"
            data-width="90"
            data-height="37"
            tabIndex="-1"
          />
        </div>
        <div className={styles.Col2}>
          <FormGroup
            style={fontStyle}
            idInput="month"
            error={errorMonth}
            index={3}
            typeInput="text"
            textLabel={monthTextLabel}
            defaultValue={payment.month}
            onChange={(event) => onChangeValue('payment', 'month', event.target.value)}
          />
        </div>
        <div className={styles.Col3}>
          <FormGroup
            style={fontStyle}
            idInput="year"
            error={errorYear}
            typeInput="text"
            index={4}
            textLabel={yearTextLabel}
            defaultValue={payment.year}
            onChange={(event) => onChangeValue('payment', 'year', event.target.value)}
          />
        </div>
        <div className={styles.Col4}>
          <FormGroup
            style={fontStyle}
            idInput="cvc"
            error={errorCvc}
            index={5}
            typeInput="text"
            textLabel={cvcTextLabel}
            defaultValue={payment.cvc}
            onChange={(event) => onChangeValue('payment', 'cvc', event.target.value)}
          />
        </div>
      </div>
      {currentProduct && currentProduct.keyName && (!currentProduct.keyName.includes('homekit') && !currentProduct.keyName.includes('appointment')) && (
        <p
          className={styles.devolution}
        >
          {messages.devolution}
        </p>
      )}
      {mercadoPagoForm}
    </div>
  )
}

export default CardData
