const data = {
  traditionalMethods: [
    {
      id: 0,
      value: 'card',
      text: 'Tarjeta de débito / crédito'
    },
    {
      id: 1,
      value: 'oxxo',
      text: 'Pago en efectivo - OXXO',
    },
    {
      id: 2,
      value: 'spei',
      text: 'Transferencia bancaria - SPEI'
    },
    {
      id: 3,
      value: 'pse',
      text: 'Pagos Seguros en Línea - PSE'
    },
    {
      id: 6,
      value: 'efecty',
      text: 'Efecty'
    },
    {
      id: 7,
      value: 'baloto',
      text: 'Baloto'
    }
  ],
  expressMethods: [
    {
      id: 0,
      value: 'applePay',
      text: 'Pay',
      enable: true
    },
    {
      id: 1,
      value: 'paypal',
      text: 'PayPal',
      enable: true
    }
  ],
  imgPayments: [
    {
      id: 1,
      type: 'visa',
      enable: true
    },
    {
      id: 2,
      type: 'mastercard',
      enable: true
    },
    {
      id: 3,
      type: 'americanExpress',
      enable: true
    },
    {
      id: 4,
      type: 'unionPay',
      enable: false,
    },
    {
      id: 10,
      type: 'efecty',
      enable: true,
      express: false
    },
    {
      id: 11,
      type: 'baloto',
      enable: true,
      express: false
    },
  ],
  titleExpress: 'EXPRESS CHECKOUT',
  titleTraditional: 'TRADITIONAL CHECKOUT'
}

export default data
