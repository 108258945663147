/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable require-jsdoc */
import React, { Component } from 'react'
import styles from './CheckoutForms.module.css'

import messages from './CheckoutForms.messages'
import PersonalData from '../PersonalData/PersonalData'
import ProgressSteps from '../ProgressSteps/ProgressSteps'
import {
  INITIAL_STEP,
  PERSONAL_DATA_STEP,
  ADDRESS_STEP,
  PAYMENTS_STEP
} from '../../Utils/constants'
import Address from '../Address/Address'
import PaymentSelector1 from '../PaymentSelector/PaymentSelector1/PaymentSelector1'
import CardData from '../CardData/CardData'
import SpeiTemplate from '../SpeiTemplate/SpeiTemplate'
import OxxoTemplate from '../OxxoTemplate/OxxoTemplate'
import CashTemplate from '../CashTemplate/CashTemplate'
import InstallmentsTwo from '../InstallmentsTwo/InstallmentsTwo'
import Installments from '../Installments/Installments'
import Error from '../Error/Error'
import SuccessPSE from '../SuccessPSE/Success'
import SuccessCard from '../SuccessCardTwo/SuccessCardTwo'
import SuccessCardPending from '../SuccessCardPending/SuccessCardPending'
import LoaderSection from '../LoaderSection/LoaderSection'
import FormPSE from '../FormPSE/FormPSE'
import PaymentSelector2 from '../PaymentSelector/PaymentSelector2/PaymentSelector2'
import { chooseCountry } from '../../Utils/utils'
import PhotoLink from '../PhotoLink/PhotoLink'

/**
 * Checkout Forms Component
 * @returns {void} .
 */
class CheckoutForms extends Component {
  /**
   * CheckoutForms constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {}
  }

  /**
   * Handle Change Value
   * @param {String} fatherKey .
   * @param {String} key .
   * @param {String} value .
   * @returns {void} .
   */
  handleChangeValue = (fatherKey, key, value) => {
    let isValidChange = false

    switch (key) {
    case 'card':
      isValidChange = this.blockingChangeValueToNumber(value, 19)
      break
    case 'month':
      isValidChange = this.blockingChangeValueToNumber(value, 2)
      break
    case 'year':
      isValidChange = this.blockingChangeValueToNumber(value, 2)
      break
    case 'cvc':
      isValidChange = this.blockingChangeValueToNumber(value, 4)
      break
    case 'zipCode':
      isValidChange = this.blockingChangeValueToNumber(value, 5)
      break
    case 'reference':
      isValidChange = value.length < 255
      break
    default:
      isValidChange = true
      break
    }
    if (isValidChange) {
      const { setFormData, formData } = this.props
      const obj = []
      const father = formData[fatherKey]
      father[key] = value
      obj[fatherKey] = father
      setFormData(obj)
    }
  }

  /**
   * Blocking Change Value To Number.
   * @param {String} value .
   * @param {String} limitLength .
   * @return {Void} .
   */
  blockingChangeValueToNumber = (value, limitLength) => {
    const re = /^[0-9\b]+$/
    if (value === '' || re.test(value)) {
      if (value.toString().length <= limitLength) {
        return true
      }
    }
    return false
  }

  /**
   * Handle the change of payment in selector.
   * @param {String} value - the paymentMethod selected
   * @return {Void} .
   */
  handleChangePayment = (value) => {
    const paymentMethodSelected = value
    this.props.onChangePaymentSelection(paymentMethodSelected)
  }

  /**
   * CheckoutForms render
   * @returns {void} .
   */
  render() {
    const {
      currentStep,
      steps,
      formError,
      formData,
      paymentMethodSelected,
      installmentsData,
      finalPrice,
      changeFinalPrice,
      showAddress,
      pricePrincipalProduct,
      currentExtraProducts,
      currentProduct,
      dataExtra,
      refContainer,
      setPSEData,
      formPSE,
      selector,
      country,
      loader,
      success,
      error,
      showSend,
      monthsAllowed,
      isAdvance,
      couponReferrals,
      category,
      uploadPictures,
      customerId,
      isSixtyForty,
      patientName,
      isLiquidation,
      publicKey
    } = this.props

    const {
      personalDataError, addressError, paymentError, pseError
    } = formError
    const { personalData, address, payment } = formData
    const {
      type, minInstallmentsAmount, enable, monthSelected, discountPrice
    } = installmentsData

    const showInstallmentsText = enable && finalPrice > minInstallmentsAmount && paymentMethodSelected === 'card'

    const countryCode = chooseCountry(country)

    // const showPhotoLink = !uploadPictures && (category === 'homekit' || category === 'appointment')
    //                     && (success && success.status !== 'in_process')

    const showPhotoLink = false

    const { price } = currentProduct || {}

    let titleProduct = currentProduct && `${currentProduct.description} `
    if (patientName) {
      titleProduct = currentProduct && `${currentProduct.description} ${messages.for} ${patientName}`
    }

    if (isSixtyForty) {
      titleProduct = currentProduct && `${currentProduct.financingText} ${messages.for} ${patientName}`
    } else if (isLiquidation) {
      titleProduct = currentProduct && currentProduct.payoffText
    }

    const stepsForm = steps.filter(step => step.name !== INITIAL_STEP)
    const currentIndex = stepsForm.findIndex(step => step.name === currentStep.name)
    let cardComponent = null
    let installmentsComponent = null
    let pseComponent = null
    if (paymentMethodSelected === 'card') {
      if (country === 'Colombia') {
        cardComponent = (
          <CardData
            currentProduct={currentProduct.keyName}
            errorDniValue={paymentError && paymentError.dniValueError}
            errorDniType={paymentError && paymentError.dniTypeError}
            errorCard={paymentError && paymentError.cardError}
            errorCardName={paymentError && paymentError.cardNameError}
            errorMonth={paymentError && paymentError.monthError}
            errorYear={paymentError && paymentError.yearError}
            errorCvc={paymentError && paymentError.cvcError}
            cardTextLabel={messages.card}
            cardNameTextLabel={messages.cardName}
            monthTextLabel={messages.month}
            yearTextLabel={messages.year}
            cvcTextLabel={messages.cvc}
            payment={payment}
            country={country}
            onChangeValue={this.handleChangeValue}
            showInstallmentsText={showInstallmentsText}
          />
        )
      }
      if (country === 'México') {
        cardComponent = (
          <CardData
            currentProduct={currentProduct.keyName}
            errorCard={paymentError && paymentError.cardError}
            errorCardName={paymentError && paymentError.cardNameError}
            errorMonth={paymentError && paymentError.monthError}
            errorYear={paymentError && paymentError.yearError}
            errorCvc={paymentError && paymentError.cvcError}
            cardTextLabel={messages.card}
            cardNameTextLabel={messages.cardName}
            monthTextLabel={messages.month}
            yearTextLabel={messages.year}
            cvcTextLabel={messages.cvc}
            payment={payment}
            country={country}
            onChangeValue={this.handleChangeValue}
            showInstallmentsText={showInstallmentsText}
          />
        )
      }
    }
    if (paymentMethodSelected === 'pse') {
      pseComponent = (
        <FormPSE
          formPSE={formPSE}
          setPSEData={setPSEData}
          bankError={pseError && pseError.bankError}
          clientTypeError={pseError && pseError.clientTypeError}
          docError={pseError && pseError.docError}
        />
      )
    }

    if (enable && finalPrice > minInstallmentsAmount && paymentMethodSelected === 'card') {
      if (monthsAllowed && monthsAllowed.length > 0) {
        installmentsComponent = type === 'discount_one_payment' ? (
          <InstallmentsTwo
            setInstallments={changeFinalPrice}
            monthsAllowed={monthsAllowed}
            finalPrice={finalPrice}
            monthSelected={monthSelected}
            discount={discountPrice}
            isAdvance={isAdvance}
            currentProduct={currentProduct}
            currencyProd={currentProduct && currentProduct.currency}
          />
        ) : (
          <Installments
            setInstallments={changeFinalPrice}
            monthsAllowed={monthsAllowed}
            price={finalPrice}
            monthSelected={monthSelected}
            currentProduct={currentProduct}
            currencyProd={currentProduct && currentProduct.currency}
          />
        )
      } else if (monthsAllowed) {
        installmentsComponent = (
          <p className={styles.InstallmentsText}>{messages.textInst[countryCode]}</p>)
      }
    }

    if (success) {
      let successComponent = ''
      switch (paymentMethodSelected) {
      case 'oxxo':
        successComponent = (
          <OxxoTemplate
            reference={success.reference}
            barcode={success.barcode}
            refContainer={refContainer}
            category={category}
            address={address}
            customerId={customerId}
            onChangeValue={this.handleChangeValue}
            paymentMethodSelected={paymentMethodSelected}
          />
        )
        break
      case 'efecty':
        successComponent = (
          <CashTemplate
            ticket={success.ticket}
            refContainer={refContainer}
            externalUrl={success.externalUrl}
            category={category}
            address={address}
            customerId={customerId}
            onChangeValue={this.handleChangeValue}
            paymentMethodSelected={paymentMethodSelected}
            efecty
          />
        )
        break
      case 'baloto':
        successComponent = (
          <CashTemplate
            ticket={success.ticket}
            refContainer={refContainer}
            externalUrl={success.externalUrl}
            category={category}
            address={address}
            customerId={customerId}
            onChangeValue={this.handleChangeValue}
            paymentMethodSelected={paymentMethodSelected}
            baloto
          />
        )
        break
      case 'spei':
        successComponent = (
          <SpeiTemplate
            clabe={success.clabe}
            bank={success.bank}
            reference={success.reference}
            refContainer={refContainer}
            category={category}
            address={address}
            customerId={customerId}
            onChangeValue={this.handleChangeValue}
            paymentMethodSelected={paymentMethodSelected}
          />
        )
        break
      case 'pse':
        successComponent = (
          <SuccessPSE
            name={success.name}
            pseLink={success.pseLink}
            currentProduct={currentProduct}
            currentExtraProducts={currentExtraProducts}
            finalPrice={finalPrice}
            pricePrincipalProduct={pricePrincipalProduct}
            isPaymentLink={this.isPaymentLink}
            installmentsData={installmentsData}
            address={address}
            showAddress={showAddress}
            dataExtra={dataExtra}
            showSend={showSend}
            category={category}
            paymentMethodSelected={paymentMethodSelected}
            customerId={customerId}
            onChangeValue={this.handleChangeValue}
          />
        )
        break
      default:
        if (country === 'Colombia') {
          successComponent = (
            <SuccessPSE
              name={success.name}
              pseLink={success.pseLink}
              currentProduct={currentProduct}
              currentExtraProducts={currentExtraProducts}
              finalPrice={finalPrice}
              pricePrincipalProduct={pricePrincipalProduct}
              isPaymentLink={this.isPaymentLink}
              installmentsData={installmentsData}
              address={address}
              showAddress={showAddress}
              dataExtra={dataExtra}
              showSend={showSend}
              category={category}
              paymentMethodSelected={paymentMethodSelected}
              customerId={customerId}
              onChangeValue={this.handleChangeValue}
              showPhotoLink={showPhotoLink}
            />
          )
        }
        if (success.status === 'in_process') {
          successComponent = (
            <SuccessCardPending
              response={success}
              coupon={couponReferrals}
              finalPrice={finalPrice}
              priceOld={price}
              installmentsData={installmentsData}
              currentProduct={currentProduct}
              category={category}
              address={address}
              paymentMethodSelected={paymentMethodSelected}
              customerId={customerId}
              onChangeValue={this.handleChangeValue}
              showPhotoLink={showPhotoLink}
              titleProduct={titleProduct}
              currencyProd={currentProduct && currentProduct.currency}
            />
          )
          break
        } else {
          successComponent = (
            <SuccessCard
              response={success}
              coupon={couponReferrals}
              finalPrice={finalPrice}
              priceOld={price}
              installmentsData={installmentsData}
              currentProduct={currentProduct}
              category={category}
              address={address}
              paymentMethodSelected={paymentMethodSelected}
              customerId={customerId}
              onChangeValue={this.handleChangeValue}
              showPhotoLink={showPhotoLink}
              titleProduct={titleProduct}
              currencyProd={currentProduct && currentProduct.currency}
              patientName={patientName}
              country={country}
              publicKey={publicKey}
            />
          )
          break
        }
      }

      return (
        <>
          {successComponent}
          {showPhotoLink && (<PhotoLink customerId={customerId} />)}
        </>
      )
    }

    if (error) {
      return <Error responseError={error} />
    }

    return (
      <div className={styles.FormsContainer}>
        <div className={styles.HeaderForm}>
          <ProgressSteps
            currentStep={currentIndex + 1}
            totalSteps={stepsForm.length}
          />
          <p className={styles.TitleSteps}>{messages.titleSteps[currentIndex]}</p>
        </div>
        <div
          className={[styles.CenterLoader, loader ? '' : styles.DisplayNone].join(' ')}
        >
          <LoaderSection />
        </div>
        <div
          className={[styles.FormsContainer, loader ? styles.DisplayNone : ''].join(' ')}
        >
          {currentStep.name === PERSONAL_DATA_STEP
         && (
           <PersonalData
             errorName={personalDataError && personalDataError.nameError}
             errorEmail={personalDataError && personalDataError.emailError}
             errorNumber={personalDataError && personalDataError.numberError}
             nameTextLabel={messages.name}
             emailTextLabel={messages.email}
             numberTextLabel={messages.movil}
             personalData={personalData}
             onChangeValue={this.handleChangeValue}
           />
         ) }
          {currentStep.name === ADDRESS_STEP
          && (
            <Address
              errorStreet={addressError && addressError.streetError}
              errorInteriorNumber={addressError && addressError.interiorNumberError}
              errorZipCode={addressError && addressError.zipCodeError}
              errorColony={addressError && addressError.colonyError}
              errorState={addressError && addressError.stateError}
              errorCity={addressError && addressError.cityError}
              errorReference={addressError && addressError.referenceError}
              streetTextLabel={messages.street}
              interiorNumberTextLabel={messages.interiorNumber}
              zipCodeTextLabel={messages.zipCode}
              colonyTextLabel={country === 'Colombia' ? messages.neighborhood : messages.colony}
              stateTextLabel={country === 'Colombia' ? messages.department : messages.state}
              cityTextLabel={country === 'Colombia' ? messages.township : messages.city}
              referenceTextLabel={messages.reference}
              address={address}
              country={country}
              onChangeValue={this.handleChangeValue}
            />
          ) }
          {currentStep.name === PAYMENTS_STEP
         && (
           <>
             {selector === 1 ? (
               <PaymentSelector1
                 onChangePayment={this.handleChangePayment}
                 paymentMethodSelected={paymentMethodSelected}
                 country={country}
                 finalPrice={finalPrice}
               />
             ) : paymentMethodSelected === '' && (
               <PaymentSelector2
                 onChangePayment={this.handleChangePayment}
                 country={country}
                 finalPrice={finalPrice}
               />
             )}
             {cardComponent}
             {installmentsComponent}
             {pseComponent}
           </>
         ) }
        </div>
      </div>
    )
  }
}

export default CheckoutForms
