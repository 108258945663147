/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { Component } from 'react'
import currency from 'currency.js'
import style from './PrincipalProduct.module.css'
import messages from './PrincipalProduct.messages'
import coupon from '../../Assets/images/svg/coupon.svg'

/**
 * PrincipalProduct Component
 * @returns {void} .
 */
class PrincipalProduct extends Component {
  /**
   * Select Product
   * @returns {void} .
   */
  selectProduct = () => {
    const { selectProduct, product } = this.props
    const id = product.keyName || product.paymentKey
    selectProduct && selectProduct(id)
  }

  /**
   * PrincipalProduct render
   * @returns {void} .
   */
  render() {
    const {
      product,
      selected,
      allowRemove,
      setExtraData,
      couponData,
      price,
      isPaymentLink,
      isLiquidation,
      patientName,
      installmentsData,
      paymentMethodSelected,
      isSixtyForty
    } = this.props
    const precision = process.env.REACT_APP_COUNTRY === 'Colombia' ? 0 : 2
    const separator = process.env.REACT_APP_COUNTRY === 'Colombia' ? '.' : ','
    let priceDiscount = `$${currency(product ? product.price : 0, { precision, separator }).format()}`
    const index = product && (product.keyName || product.paymentKey)
    let couponText = ''

    let finalPrice = price || (product && product.finalPrice)
    const applyCoupon = couponData && couponData.applyCoupon
    const currentCoupon = couponData && couponData.currentCoupon
    if (currentCoupon && Object.keys(currentCoupon).length !== 0 && applyCoupon) {
      const couponDiscount = currentCoupon.Discount
      if (currentCoupon.Apply_Total) {
        priceDiscount = `$${currency(finalPrice, { precision, separator }).format()}`
        if (currentCoupon.Discount_Type === 'percentage') {
          if (couponDiscount) couponText = `${currentCoupon.Code} ${couponDiscount}% de descuento`
          else couponText = currentCoupon.Code
          finalPrice *= 1 - (couponDiscount / 100)
        } else {
          if (couponDiscount) couponText = `${currentCoupon.Code} con $${couponDiscount}.00 de descuento`
          else couponText = currentCoupon.Code
          finalPrice -= couponDiscount
        }
      } else if (couponDiscount <= finalPrice) {
        priceDiscount = `$${currency(finalPrice, { precision, separator }).format()}`
        finalPrice = couponDiscount
        couponText = `${currentCoupon.Code}`
      }
    }

    const currencyProd = product && product.currency
    const totalPrice = product ? `$${currency(finalPrice, { precision, separator }).format()} ${currencyProd}` : ''

    let titleProduct = product && `${product.description} `
    if (patientName) {
      titleProduct = product && `${product.description} ${messages.for} ${patientName}`
    }

    if (isSixtyForty) {
      titleProduct = product && `${product.financingText} ${messages.for} ${patientName}`
    } else if (isLiquidation) {
      titleProduct = product && product.payoffText
    }

    const showTextInst = installmentsData && installmentsData.enable
                      && installmentsData.minInstallmentsAmount < finalPrice
                      && paymentMethodSelected === 'card'

    return (
      <div
        role="button"
        className={[style.PrincipalContainer, selected ? style.Selected : ''].join(' ')}
        onClick={() => this.selectProduct()}
        tabIndex={0}
      >
        <div className={style.DescHeader}>
          <p className={style.Description}>{titleProduct}</p>
          {allowRemove && (
            <div
              tabIndex="0"
              role="button"
              className={style.DeleteProduct}
              onClick={() => setExtraData(index)}
            >
              {messages.close}
            </div>
          )}
        </div>
        {couponText && (
          <div className={style.CouponText}>
            <img
              alt="prod"
              src={coupon}
              className={style.ImageCoupon}
            />
            {couponText}
          </div>
        )}
        <div className={[style.Prices, couponText ? style.Row : ''].join(' ')}>
          {!isPaymentLink && (
            <div className={style.LastPrice}>
              {isSixtyForty ? messages.financingText : (<strike>{priceDiscount}</strike>)}
            </div>
          )}
          <div
            className={style.TotalPrices}
            id="price_card"
          >
            {totalPrice}
          </div>
        </div>
        {showTextInst && (
          <div className={style.TextInstallments}>
            {messages.textInstallments}
          </div>
        )}
        {product && product.keyName && (!product.keyName.includes('homekit') && !product.keyName.includes('appointment'))
        && (
          <div className={style.TextDevolution}>
            {messages.devolution}
          </div>
        )}
      </div>
    )
  }
}

export default PrincipalProduct
