import React from 'react'
import currency from 'currency.js'
import styles from './SuccessCardPending.module.css'
import logo from '../../Assets/images/svg/moons.svg'
import messages from './SuccessCardPending.messages'
import cosmonauta from '../../Assets/images/png/cosmonautaPending.png'
import clock from '../../Assets/images/svg/clock.svg'

const logoComponent = (
  <div className={styles.Logo}>
    <img
      alt="logo"
      src={logo}
    />
  </div>
)

/**
 * SuccessCardPending Component
 * @param {Object} props .
 * @return {void}
 */
const SuccessCardPending = (props) => {
  const {
    response, finalPrice, installmentsData, titleProduct, priceOld, currencyProd
  } = props

  const precision = process.env.REACT_APP_COUNTRY === 'Colombia' ? 0 : 2
  const separator = process.env.REACT_APP_COUNTRY === 'Colombia' ? '.' : ','

  const { priceInstallments } = installmentsData || {}
  const price = `$${currency(priceInstallments || finalPrice, { precision, separator }).format()} ${currencyProd}`
  const priceOldTemp = `$${currency(priceOld, { precision, separator }).format()} ${currencyProd}`

  const transactionId = response && (response.stripePaymentId
      || response.conektaPaymentId || response.mercadoPagoPaymentId
      || response.payuPaymentId)

  return (
    <div className={styles.SuccessContainer}>
      {logoComponent}
      <div className={styles.HeaderSuccess}>
        <p className={styles.HeaderTitle}>{messages.headerTitle}</p>
        <img
          className={styles.HeroSuccess}
          alt="hero"
          src={cosmonauta}
        />
      </div>
      <div className={styles.PendingSuccess}>
        <img
          className={styles.Clock}
          alt="clock"
          src={clock}
        />
        <p className={styles.PendingTitle}>{messages.pending}</p>
      </div>
      <div className={styles.Wrapper}>
        <div className={styles.Amount}>
          <p className={styles.Item}>
            {messages.paymentAmount}
            {price}
          </p>
        </div>
        <div className={styles.CenterItem}>
          <p className={styles.TitleProduct}>{titleProduct}</p>
          <p className={styles.Price}>{price}</p>
        </div>
        <div
          className={styles.Transaction}
          id="confirmation"
        >
          <p className={styles.TextTransaction}>{messages.transactionId}</p>
          <div className={styles.TransactionId}>
            <p>{transactionId}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessCardPending
