/* eslint-disable max-statements */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable complexity */
import React, { Component } from 'react'
import currency from 'currency.js'
import styles from './ProductInformation.module.css'
import messages from './ProductInformation.messages'
import Coupon from '../Coupon/Coupon'
import { chooseCountry } from '../../Utils/utils'
import imageProduct from '../../Assets/images/png/futura_profile.png'

/**
 * ProductInformation Component
 * @returns {void} .
 */
class ProductInformation extends Component {
  /**
   * ProductInformation constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  /**
   * Set Extra Data
   * @param {String} index .
   * @returns {void} .
   */
  setExtraData = (index) => {
    const { setExtraProducts, currentExtraProducts } = this.props
    const { quantity } = currentExtraProducts[index]
    const newExtra = { keyName: index, quantity: quantity - 1 }
    setExtraProducts && setExtraProducts(newExtra)
  }

  extraProductsComponent = () => {
    const { currentExtraProducts, dataExtra } = this.props
    const component = []
    for (const index in currentExtraProducts) {
      if ({}.hasOwnProperty.call(currentExtraProducts, index)) {
        const extra = dataExtra.filter(dataExt => dataExt.keyName === index)[0]
        const precision = process.env.REACT_APP_COUNTRY === 'Colombia' ? 0 : 2
        const separator = process.env.REACT_APP_COUNTRY === 'Colombia' ? '.' : ','
        const priceExtra = `$${currency(extra.finalPrice, { precision, separator }).format()} ${extra.currency}`

        let { quantity } = currentExtraProducts[index]
        while (quantity) {
          component.push(
            <div
              key={`${extra.objectId}_${quantity}`}
              className={styles.TitlePriceExtra}
            >
              <div className={styles.PriceExtra}>
                <p className={styles.NameProduct}>{extra && extra.name}</p>
                <div
                  tabIndex="0"
                  role="button"
                  className={styles.DeleteProduct}
                  onClick={() => this.setExtraData(index)}
                >
                  {messages.close}
                </div>
              </div>

              <div className={styles.PriceExtra}>
                <p className={styles.TagPrice}>
                  {messages.price}
                </p>
                <p className={styles.NumberPrice}>
                  {priceExtra}
                </p>
              </div>
              <hr className={styles.Separator} />
            </div>
          )
          quantity -= 1
        }
      }
    }
    return component
  }

  /**
   * Component Render
   * @return {void} .
   */
  render() {
    const {
      currentProduct,
      currentExtraProducts,
      couponData,
      finalPrice,
      showSend,
      pricePrincipalProduct,
      isPaymentLink,
      installmentsData,
      wasSendToBack,
      isLiquidation,
      patientName,
      isSixtyForty,
      urlCoupon
    } = this.props

    const { priceInstallments } = installmentsData || {}
    const currencyProd = `${currentProduct ? currentProduct.currency : ''}`
    const precision = process.env.REACT_APP_COUNTRY === 'Colombia' ? 0 : 2
    const separator = process.env.REACT_APP_COUNTRY === 'Colombia' ? '.' : ','
    const priceTotal = `$${currency(priceInstallments || finalPrice, { precision, separator }).format()}`
    const priceProduct = currentProduct ? `$${currency(pricePrincipalProduct
      || currentProduct.finalPrice, { precision, separator }).format()} ${currencyProd}` : 0

    const price = (currentProduct && currentProduct.price) || 0
    const onlyPrice = currentProduct ? `$${currency(price, { precision, separator }).format()}` : 0

    const countryCode = chooseCountry(process.env.REACT_APP_COUNTRY)
    const priceSend = `$${currency(messages.priceSend[countryCode], { precision: 0, separator }).format()}`

    let savedPrice = price > finalPrice ? price - finalPrice : 0
    savedPrice = `$${currency(savedPrice, { precision, separator }).format()} ${currencyProd}`

    const nameProduct = currentProduct && (currentProduct.keyName
      || currentProduct.paymentKey)


    const showCoupon = !isPaymentLink && couponData && couponData.showCoupon
                     && !wasSendToBack

    let titleProduct = currentProduct && `${currentProduct.description} `
    if (patientName) {
      titleProduct = currentProduct && `${currentProduct.description} ${messages.for} ${patientName}`
    }

    if (isSixtyForty) {
      titleProduct = currentProduct && `${currentProduct.financingText} ${messages.for} ${patientName}`
    } else if (isLiquidation) {
      titleProduct = currentProduct && currentProduct.payoffText
    }
    return (
      <div className={styles.InfoContainer}>
        <div className={styles.Wrapper}>
          <div className={styles.TextTitle}>
            {messages.titleInfo}
          </div>
          <div className={styles.PrincipalProduct}>
            {imageProduct ? (
              <img
                alt="prod"
                src={imageProduct}
                className={[imageProduct ? '' : styles.ImageProducts, styles.MarginImage].join(' ')}
              />
            ) : <div className={styles.ImageProducts} />}
            <div className={styles.TitlePrice}>
              <p className={styles.NameProduct}>
                {titleProduct}
              </p>
            </div>
          </div>
          <hr className={styles.Separator} />
          {currentExtraProducts && this.extraProductsComponent()}
          {showCoupon && (
            <Coupon
              couponData={couponData}
              nameProduct={nameProduct}
              urlCoupon={urlCoupon}
            />
          )}
          {showSend && (
            <>
              <div className={styles.Send}>
                <div className={styles.SendItemFirst}>
                  <p className={styles.TextItem}>{messages.subtotal}</p>
                  <p className={styles.TextItem}>{`${priceTotal} ${currencyProd}`}</p>
                </div>
              </div>
              <hr className={styles.Separator} />
            </>
          )}
          <div className={styles.TotalPrice}>
            <p className={[styles.TextItemBold, styles.TotalText].join(' ')}>{messages.total}</p>
            <div className={styles.RightPrice}>
              <p className={[styles.TextItem, styles.Currency].join(' ')}>{currencyProd}</p>
              <p
                id="total_price"
                className={[styles.TextItemBold, styles.TotalNumber].join(' ')}
              >
                {priceTotal}
              </p>
            </div>
          </div>
          <p className={styles.Iva}>{messages.iva}</p>
          {currentProduct && currentProduct.keyName && (!currentProduct.keyName.includes('homekit') && !currentProduct.keyName.includes('appointment'))
            && (
              <p
                className={styles.Iva}
              >
                {messages.devolution}
              </p>
            )}
        </div>
      </div>
    )
  }
}

export default ProductInformation
