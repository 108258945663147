import React from 'react'
import Barcode from 'react-barcode'
import ReactToPrint from 'react-to-print'
import styles from './OxxoTemplate.module.css'
import messages from './OxxoTemplate.messages'
import { isObject } from '../../Utils/utils'
import SuccessAddress from '../SuccessAddress/SuccessAddress'

/**
 * OxxoTemplate Component
 * @returns {React.Component} OxxoTemplate .
 */
const OxxoTemplate = ({
  reference, barcode, refContainer,
  category, address, onChangeValue, customerId, paymentMethodSelected
}) => {
  let barcodeUrl = null
  if (!isObject(barcode)) {
    barcodeUrl = barcode
  }

  const arrayReference = reference && reference.match(/.{1,4}/g)
  return (
    <div className={styles.OxxoContainer}>
      <p className={styles.OxxoReference}>{messages.title}</p>
      <p className={styles.OxxoTitle}>{messages.subTitle}</p>

      <div className={styles.RefContainer}>
        {reference && arrayReference.map((item, index) => (
          <>
            <div
              key={index}
              className={styles.RefItem}
            >
              {item}
            </div>
          </>
        ))}
      </div>

      <p className={styles.Notice}>
        {messages.scanner}
      </p>

      {barcodeUrl ? (
        <>
          <img
            className={styles.Barcode}
            src={barcodeUrl}
            alt={barcodeUrl}
          />
          {reference}
        </>
      ) : (
        <Barcode
          value={barcode && barcode.content}
          height={80}
          marginTop={30}
          width={1.5}
        />
      )}

      <div className={styles.Buttons}>
        <ReactToPrint
          trigger={() => (
            <div className={styles.ButtonDownload}>
              {messages.textDownload}
            </div>
          )}
          content={() => refContainer.current}
        />
      </div>
      <SuccessAddress
        category={category}
        address={address}
        onChangeValue={onChangeValue}
        customerId={customerId}
        paymentMethodSelected={paymentMethodSelected}
      />
    </div>
  )
}

export default OxxoTemplate
