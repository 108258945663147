/* eslint-disable complexity */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-child-element-spacing */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import currency from 'currency.js'
import styles from './SuccessCardTwo.module.css'
import styleHeader from '../Header/Header.module.css'
import messages from './SuccessCardTwo.messages'
import hero from '../../Assets/images/svg/futura_labs.svg'
import { chooseCountry } from '../../Utils/utils'

const countryRefs = {
  "México":{
    "booking": process.env.REACT_APP_BOOKING_URL_MX
  },
  "Colombia":{
    "booking":process.env.REACT_APP_BOOKING_URL_CO
  }
}

/**
 * SuccessCardTwo Component
 * @param {Object} response - json to the response
 * @return {void}
 */
class SuccessCardTwo extends React.Component {
  /**
   *
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      show: true,
    }

    this.country = process.env.REACT_APP_COUNTRY
    this.countryCode = chooseCountry(this.country).toLowerCase()
  }


  componentDidMount(){
    const {coupon, patientName, currentProduct} = this.props


    const productKeyName = currentProduct.keyName
    let paymentType = 'payoff'
    if(productKeyName && (productKeyName.includes('appointment') || productKeyName.includes('homekit'))){
      paymentType = 'advance'
    }
  }

  /**
   * @returns {void}
   */
  render() {
    const {
      response, finalPrice, installmentsData, category,
      address, onChangeValue, addressError, customerId,
      paymentMethodSelected, titleProduct, priceOld, currencyProd, country, publicKey
    } = this.props


    const precision = process.env.REACT_APP_COUNTRY === 'Colombia' ? 0 : 2
    const separator = process.env.REACT_APP_COUNTRY === 'Colombia' ? '.' : ','

    const { priceInstallments } = installmentsData || {}
    const price = `$${currency(priceInstallments || finalPrice, { precision, separator }).format()} ${currencyProd}`
    const priceOldTemp = `$${currency(priceOld, { precision, separator }).format()} ${currencyProd}`

    const transactionId = response && (response.stripePaymentId
      || response.conektaPaymentId || response.mercadoPagoPaymentId
      || response.payuPaymentId)

    return (
      <div className={styles.SuccessContainer}>
        <div className={[styleHeader.Logo].join(' ')}>
          <img
            alt="logo"
            src={hero}
          />
        </div>
        <div className={styles.Wrapper}>
          <div className={styles.Amount}>
            <p className={styles.Item}>
              {messages.paymentAmount}
              {price}
            </p>
          </div>
          <p className={styles.Buy}>{messages.buy}</p>
          <div className={styles.CenterItem}>
            <p className={styles.TitleProduct}>{titleProduct}</p>
            <p className={styles.Price}>{price}</p>
          </div>
          <div
            className={styles.Transaction}
            id="confirmation"
          >
            <p>{messages.transactionId}</p>
            <div className={styles.TransactionId}>
              <p>{transactionId}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SuccessCardTwo
