import React from 'react'
import style from './Error.module.css'
import logo from '../../Assets/images/svg/moons.svg'
import messages from './Error.messages'

const logoComponent = (
  <div className={style.Logo}>
    <img
      alt="logo"
      src={logo}
    />
  </div>
)

/**
 * Error Component
 * @param {Object} responseError - json to the response of the error
 * @return {void}
 */
class Error extends React.Component {
  /**
   *
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    const { handleErrorButton, responseError } = this.props
    this.handleErrorButton = handleErrorButton
    this.responseError = responseError
  }

  /**
   * @param {Event} event - onClick event
   * @return {void} .
   */
  handleClick = event => {
    event.preventDefault()
    window.location.reload()
  }

  /**
   * Response Detail
   * @param {Object} item .
   * @return {void} .
   */
  responseDetail = (item) => {
    let message = ''
    if (item && typeof item.message === 'object') {
      message = item.message && item.message.message
    } else {
      message = item.message
    }
    return <li className={style.ErrorMessage}>{message}</li>
  }

  /**
   * @returns {void}
   */
  render() {
    const position = 0

    window.scrollTo(position, position)
    return (
      <div className={style.ErrorContainer}>
        {logoComponent}
        <h1>{messages.title}</h1>
        <h2>{messages.subTitle}</h2>
        <h2 className={style.Blue}>{messages.email}</h2>
        {this.responseDetail(this.responseError)}
        <div className={style.buttonContainer}>
          <div
            role="button"
            tabIndex={0}
            className={style.TryAgain}
            onClick={this.handleClick}
          >
            {messages.tryAgain}
          </div>
        </div>
      </div>
    )
  }
}

export default Error
