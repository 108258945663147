
const messages = {
  titleInfo: 'Resumen de Cuenta',
  iva: '*El costo incluye IVA',
  total: 'Total',
  subtotal: 'SubTotal',
  send: 'Envío',
  price: 'Precio',
  finalPrice: 'Precio Final',
  saved: 'Ahorras',
  close: 'X',
  free: 'Gratis',
  for: 'para',
  devolution: '*Incluido: La política de devoluciones de 30 días, sin condiciones.',
  priceSend: {
    MX: '120',
    CO: '25000'
  }
}

export default messages
