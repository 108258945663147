const messages = {
  initialBanks: 'A continuación seleccione su banco',
  clientType: [
    {
      id: 0,
      description: 'Tipo de Cliente',
      pseCode: null
    },
    {
      id: 1,
      description: 'Persona Natural',
      pseCode: 'N'
    },
    {
      id: 2,
      description: 'Persona Jurídica',
      pseCode: 'J'
    }
  ],
  document: [
    {
      id: 0,
      description: 'Identificación',
      pseCode: null
    },
    {
      id: 1,
      description: '(Cédula de ciudadanía)',
      pseCode: 'CC'
    },
    {
      id: 2,
      description: '(Cédula de extranjería)',
      pseCode: 'CE'
    },
    {
      id: 3,
      description: '(Número de Identificación Tributario)',
      pseCode: 'NIT'
    },
    {
      id: 4,
      description: '(Tarjeta de Identidad)',
      pseCode: 'TI'
    },
    {
      id: 5,
      description: '(Pasaporte)',
      pseCode: 'PP'
    },
    {
      id: 6,
      description: '(Identificador único de cliente)',
      pseCode: 'IDC'
    },
    {
      id: 7,
      description: '(Número móvil)',
      pseCode: 'CEL'
    },
    {
      id: 8,
      description: '(Registro civil)',
      pseCode: 'RC'
    },
    {
      id: 9,
      description: '(Documento de identificación extranjero)',
      pseCode: 'DE'
    },
  ]
}

export default messages
