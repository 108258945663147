import React from 'react'
import Banner from '../Banner/Banner'
import styles from './Shell.module.css'

/**
 * Shell component.
 * @returns {void} .
 */
const Shell = ({
  children, bannerDisabled
}) => (
  <div className={styles.Container}>
    {!bannerDisabled && <Banner />}
    {children}
  </div>
)

export default Shell
