/* eslint-disable max-statements */
/**
 * Conekta success response handler
 * @param {object} formData .
 * @param {function} paymentData .
 * @param {function} success .
 * @param {function} error .
 * @param {String} country .
 * @return {void} .
 */
const sendBack = async (formData, paymentData, success, error) => {
  const BasicAuthUsername = 'user'
  const BasicAuthPassword = 'secret'
  const auth = `${BasicAuthUsername}:${BasicAuthPassword}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`

  const input = {
    ...formData,
    ...paymentData
  }
  
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: authEncoded,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(input)
  }

  try {
    const urlCheckout = process.env.REACT_APP_LAMBDA_URL

    const response = await fetch(urlCheckout, requestOptions)
    const data = await response.json()
    data.name = formData.name

    if (response.ok) {
      success && success(data)
    } else {
      error && error(data)
    }
  } catch (err) {
    error && error(err)
  }
}

/**
 * Get All Bank
 * @return {void} .
 */
const getAllBankPSE = async () => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  }

  const urlBanks = process.env.REACT_APP_PSE_URL

  const response = await fetch(urlBanks, requestOptions)
  const data = await response.json()

  if (response.ok && data) {
    return data.banks
  }
}

export default { sendBack, getAllBankPSE }
