import React from 'react'
import moon from '../../Assets/images/png/lunafooter.png'
import ring from '../../Assets/images/png/path.png'
import styles from './LoaderFullScreen.module.css'

/**
 * Loader Full Screen Component
 * @returns {React.Component} LoaderFullScreen .
 */
const LoaderFullScreen = ({message}) => {
  window.scrollTo(0, 0)
  return (
    <div className={styles.Container}>
      <div className={styles.SealRing}>
        <img
          src={ring}
          alt="Animacion"
        />
      </div>
      <div className={styles.ContainerMessage}>{message}</div>
    </div>
  )
}

export default LoaderFullScreen
