import React from 'react'
import { withRouter } from 'react-router-dom'
import Checkout from '../Checkout/Checkout'
import ErrorQuery from '../Error404/Error404'
import Services from '../../Apis/Back4AppServices'

/**
 * CheckoutFromPaymentLink Component
 * @returns {void} .
 */
class CheckoutFromPaymentLink extends React.Component {
  /**
   * CheckoutFromPaymentId constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {
      success: null,
      loading: true,
      patient: {}
    }
    const { match } = props
    this.country = process.env.REACT_APP_COUNTRY

    const params = match.params ? match.params : null
    this.paymentKeyName = params.paymentKeyName ? params.paymentKeyName : null
    this.salesAgentId = params.salesAgentId ? params.salesAgentId : null
    this.customerData = {}
  }

  /**
   * componentDidMount
   * @returns {void} .
   */
  async componentDidMount() {
    const patientData = await Services.getPatientByPaymentLink(this.paymentKeyName)
    this.setPatient(patientData)
    const countryPaymentLink = patientData && patientData.country
    if (this.country !== countryPaymentLink) {
      this.changeLoading(false, false)
    } else if (this.country !== patientData.patientCountry) {
      this.changeLoading(false, false)
    } else if (patientData) {
      this.customerData = {
        name: patientData.name,
        email: patientData.email,
        phone: patientData.phone
      }
      this.changeLoading(true, false)
    } else {
      this.changeLoading(false, false)
    }
  }

  /**
   * Set Patient Data
   * @param {Object} patientData .
   * @returns {void} .
   */
  setPatient = (patientData) => {
    this.setState({ patient: patientData })
  }

  /**
   * changeLoading
   * @param {Object} success .
   * @param {Object} loading .
   * @returns {void} .
   */
  changeLoading = (success, loading) => {
    this.setState({ success, loading })
  }

  /**
   * CheckoutFromPaymentLink render
   * @returns {void} .
   */
  render() {
    const {
      isAdvance,
      showPersonalData,
      showAddress,
      showCoupon,
      showProductInformation,
      extraProductsAllowed,
      showSend,
      selector
    } = this.props
    const { success, loading, patient } = this.state

    if (!loading) {
      if (!success) {
        return <ErrorQuery />
      }

      return (
        <Checkout
          paymentKeyName={this.paymentKeyName}
          isAdvance={isAdvance}
          patient={patient}
          showPersonalData={showPersonalData}
          showAddress={showAddress}
          showCoupon={showCoupon}
          showProductInformation={showProductInformation}
          customerData={this.customerData}
          extraProductsAllowed={extraProductsAllowed}
          showSend={showSend}
          selector={selector}
          salesAgentId={this.salesAgentId}
        />
      )
    }
    return <></>
  }
}

export default withRouter(CheckoutFromPaymentLink)
