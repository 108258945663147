const products = {
  MX: {
    homekit: 'homekit-mx',
    cita: 'appointment-mx',
    simple: 'simple-mx',
    moderado: 'moderado-mx',
    complejo: 'complejo-mx',
    liquidacion: 'liquidacion-mx',
    moonlight: 'moonlight-mx',
    retainers: 'retainers-mx'
  },
  CO: {
    homekit: 'homekit-co',
    cita: 'appointment-co',
    simple: 'simple-co',
    moderado: 'moderado-co',
    complejo: 'complejo-co',
    liquidacion: 'liquidacion-co',
    moonlight: 'moonlight-co',
    retainers: 'retainers-co',
    "homekit-promo":  'homekit-promo'
  }
}

export default products
