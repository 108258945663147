import React, { Component } from 'react'
import moment from 'moment'
import style from './TimeCounter.module.css'
import messages from './TimeCounter.messages'
import FullModal from './FullModal'
import timerIcon from '../../Assets/images/svg/timer-alert.svg'

/**
 * TimeCounter Component
 * @returns {void} .
 */
class TimeCounter extends Component {
  /**
   * TimeCounter constructor
   * @returns {void} .
   */
  constructor() {
    super()
    this.state = {
      time: {
        minutes: '11',
        seconds: '00',
        expired: false
      },
      timeExpired: false,
      showModal: false
    }
  }

  /**
   * Component Did Mount
   * @returns {void} .
   */
  componentDidMount() {
    this.countDown()
  }

  /**
   * Component Will Unmount
   * @returns {void} .
   */
  componentWillUnmount() {
    this.interval && clearInterval(this.interval)
  }

  countDown = () => {
    const diff = 0
    const second = 1000
    const { time } = this.state
    const { setExpired } = this.props
    const countDownDate = moment().add(time.minutes, 'minute').add(1, 'seconds')

    // Update the count down every 1 second
    this.interval = setInterval(() => {
      const now = moment()

      const distance = countDownDate - now

      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (minutes.toString().length === 1) {
        minutes = `0${minutes}`
      }
      if (seconds.toString().length === 1) {
        seconds = `0${seconds}`
      }

      const currentTime = {
        minutes,
        seconds
      }
      this.setState({ time: currentTime })

      if (distance < diff) {
        clearInterval(this.interval)
        const timeExpired = {
          expired: true,
          minutes: '01',
          seconds: '00'
        }
        this.setState({ time: timeExpired, timeExpired: true, showModal: true })
        setExpired && setExpired()
      }
    }, second)
  }

  clearPopupTimer = () => {
    const { clearPopup } = this.props
    this.setState({ showModal: false })
    clearPopup()
  }

  /**
   * TimeCounter render
   * @returns {void} .
   */
  render() {
    const { time, timeExpired, showModal } = this.state
    const { minutes, seconds } = time
    const timeMinute = `${minutes}:${seconds}`
    const title = messages.advice
    

    const popupTime = (
      <div className={style.PopupCard}>
        <img
          src={timerIcon}
          alt="alert-icon-timer"
          className={style.TimerIconAlert}
        />
        <div className={style.PopupTitle}>{messages.popupTitle}</div>
        <div className={style.PopupDesc}>{messages.popupDesc}</div>
        <button
          type="button"
          onClick={this.clearPopupTimer}
          className={style.PopupBtn}
        >
          {messages.popupBtn}
        </button>
      </div>
    )

    return (
      timeExpired ? (
        <FullModal
          open={showModal}
          content={popupTime}
        />
      ) : ( null )
    )
  }
}

export default TimeCounter
