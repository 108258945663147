/* eslint-disable no-undefined */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import styles from './Banner.module.css'
import messages from './Banner.messages'
import logoBlack from '../../Assets/images/png/blackWeek.png'

/**
 * Banner Component.
 * @returns {void} .
 */
const Banner = () => (
  <div className={styles.MainContainer}>
    <div className={styles.Banner}>
      <img
        alt="logoBlack"
        src={logoBlack}
      />
      <p className={styles.Discount}>{messages.discount}</p>
    </div>
  </div>
)

export default Banner
