const messages = {
  subTitle: 'Si tienes dudas, escribenos a ',
  email: 'notifications@mymoons.mx',
  headerTitle: 'Estamos procesando tu pago',
  pending: 'El banco está tardando un poco en aprobar tu pago, cuando lo haga nosotros te enviaremos un email con todos los detalles',
  transactionId: 'Número de transacción: ',
  paymentAmount: 'Monto de transacción: ',
}

export default messages
