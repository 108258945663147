const messages = {
  Installments_MX_Complex: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal}',
      value: 0,
      percent: 0
    },
    {
      id: 1,
      text: '3 pagos de {price}',
      value: 3,
      percent: 5
    },
    {
      id: 2,
      text: '6 pagos de {price}',
      value: 6,
      percent: 7.5
    },
    {
      id: 3,
      text: '9 pagos de {price}',
      value: 9,
      percent: 10
    },
    {
      id: 4,
      text: '12 pagos de {price}',
      value: 12,
      percent: 12.5
    },
    {
      id: 5,
      text: '18 pagos de {price}',
      value: 18,
      percent: 18.5
    },
    {
      id: 6,
      text: '24 pagos de {price}',
      value: 24,
      percent: 29.954
    }
  ],
  Installments_MX: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal}',
      value: 0,
      percent: 0
    },
    {
      id: 1,
      text: '3 pagos de {price}',
      value: 3,
      percent: 5
    },
    {
      id: 2,
      text: '6 pagos de {price}',
      value: 6,
      percent: 7.5
    },
    {
      id: 3,
      text: '9 pagos de {price}',
      value: 9,
      percent: 10
    },
    {
      id: 4,
      text: '12 pagos de {price}',
      value: 12,
      percent: 12.5
    },
    {
      id: 5,
      text: '18 pagos de {price}',
      value: 18,
      percent: 18.5
    },
    {
      id: 6,
      text: '24 pagos de {price}',
      value: 24,
      percent: 26.256
    }
  ],
  Installments_CO: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal}',
      value: 0,
      percent: 0
    },
    {
      id: 1,
      text: '3 pagos de {price}',
      value: 3,
      percent: 5
    },
    {
      id: 2,
      text: '6 pagos de {price}',
      value: 6,
      percent: 7.5
    },
    {
      id: 3,
      text: '12 pagos de {price}',
      value: 12,
      percent: 12.5
    }
  ],
}

export default messages
