/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable radix */
const Validator = {

  /**
   * Validate Personal Data.
   * @param {string} data .
   * @returns {void} .
   */
  validatePersonalData: data => {
    const { name, email, number } = data

    /**
     * Validate input name.
     * @param {string} nameClient .
     * @returns {void} .
     */
    const validateInputName = (nameClient) => {
      let nameError = false
      if (nameClient === null || nameClient === '') {
        nameError = 'El nombre no puede estar vacio!'
      }
      if (nameClient.split(' ').length <= 1) {
        nameError = 'Necesitamos al menos un apellido'
      }
      return nameError
    }

    /**
     * Validate the email
     * @param {String} emailClient - value on the input email
     * @returns {*} .
     */
    const validateInputEmail = emailClient => {
      let emailError = false
      const re = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!re.test(emailClient.toString().toLowerCase())) {
        emailError = 'Error en el correo, verifícalo!'
      }
      return emailError
    }

    /**
     * Validate the phone
     * @param {String} phone - value on the input phone
     * @returns {*} .
     */
    const validateInputPhone = phone => {
      let phoneError = false
      if (phone.toString().length < 10) {
        phoneError = 'El número debe de tener 10 digitos.'
      }
      return phoneError
    }

    const nameError = validateInputName(name)
    const emailError = validateInputEmail(email)
    const numberError = validateInputPhone(number)
    if (nameError || emailError || numberError) {
      return {
        personalDataError: {
          nameError,
          emailError,
          numberError
        }
      }
    }
    return false
  },

  /**
   * Validate Address Data.
   * @param {string} data .
   * @returns {void} .
   */
  validateAddress: data => {
    const {
      street,
      interiorNumber,
      zipCode,
      colony,
      state,
      city
    } = data

    /**
     * @param {String} input - the input to validate
     * @param {String} errorText - the error to set if the input is not correct
     * @return {String} error
     */
    const validateInputText = (input, errorText) => {
      let error = null
      if (!input || input === '') {
        error = errorText
      }
      return error
    }

    /**
     * @param {String} input - the zipCode to validate
     * @returns {String} - the errorText if error, null if not
     */
    const validateZipCode = input => {
      const country = process.env.REACT_APP_COUNTRY
      if (country === 'Colombia') {
        return null
      }
      if (!input || input === '') {
        return 'Campo requerido'
      }
      if (input.length < 5 || input.length > 5) {
        return 'El codigo postal debe de tener 5 dígitos'
      }
      return null
    }
    const streetError = validateInputText(street, 'Campo requerido')
    const interiorNumberError = validateInputText(interiorNumber, 'Campo requerido')
    const zipCodeError = validateZipCode(zipCode)
    const colonyError = validateInputText(colony, 'Campo requerido')
    const stateError = validateInputText(state, 'Campo requerido')
    const cityError = validateInputText(city, 'Campo requerido')
    if (streetError
      || interiorNumberError
      || zipCodeError
      || colonyError
      || cityError
    ) {
      return {
        addressError: {
          streetError,
          interiorNumberError,
          zipCodeError,
          colonyError,
          stateError,
          cityError
        }
      }
    }
    return null
  },

  /**
   * Validate Card Data.
   * @param {string} data .
   * @returns {void} .
   */
  validateCardData: data => {
    const {
      card,
      cardName,
      cvc,
      year,
      month
    } = data
    let cardError = null
    let cardNameError = null
    let cvcError = null
    let monthError = null
    let yearError = null
    let dniTypeError = null
    let dniValueError = null
    const country = process.env.REACT_APP_COUNTRY
    if (card.length <= 16 || country === 'México') {
      if (!window.Stripe.card.validateCardNumber(card)) {
        cardError = 'Error en tarjeta, verifícala'
      }
    }
    if (!cardName || cardName === '') {
      cardNameError = 'Campo requerido'
    } else if (cardName.split(' ').length <= 1) {
      cardNameError = 'Necesitamos al menos un apellido'
    }
    if (!window.Stripe.card.validateCVC(cvc)) {
      cvcError = 'Error en cvc'
    }
    if (!month || month === '') {
      monthError = 'Mes a 2 digitos'
    }
    if (!year || year === '') {
      yearError = 'Año a 2 digitos'
    }
    if (year.toString().length < 2 && year.toString() > 0) {
      yearError = 'Verifica el año'
    }
    const monthInt = parseInt(month)
    const yearInt = parseInt(year)
    const date = new Date()
    const currentYear = date.getFullYear().toString().slice(0, 2)
    const currentMonth = date.getMonth()
    if ((yearInt === currentYear && monthInt < currentMonth) || (monthInt > 12) || monthInt <= 0) {
      monthError = 'Verifica el mes'
    }
    if (yearInt < currentYear) {
      yearError = 'Verifica el año'
    }

    if (country === 'México' && (cardError || cardNameError || cvcError || monthError || yearError)) {
      return {
        paymentError: {
          cardError,
          cardNameError,
          cvcError,
          monthError,
          yearError
        }
      }
    }
    if (country === 'Colombia') {
      const { dniType, dniValue } = data
      if (!dniType || dniType === '') {
        dniTypeError = 'Campo requerido'
      }
      if (!dniValue || dniValue === '') {
        dniValueError = 'Campo requerido'
      }
      if (cardError
        || cardNameError
        || cvcError
        || monthError
        || yearError
        || dniTypeError
        || dniValueError
      ) {
        return {
          paymentError: {
            cardError,
            cardNameError,
            cvcError,
            monthError,
            yearError,
            dniTypeError,
            dniValueError
          }
        }
      }
    }
    return false
  },

  /**
   * Validate PSE Data.
   * @param {string} data .
   * @returns {void} .
   */
  validatePSEData: data => {
    const {
      bank, docType, docValue, clientType
    } = data

    /**
     * Validate input name.
     * @param {string} pseCode .
     * @returns {void} .
     */
    const validateBank = (pseCode) => {
      let bankError = false
      if (pseCode === null || pseCode === '' || !pseCode) {
        bankError = 'Debe seleccionar un banco'
      }
      return bankError
    }

    /**
     * Validate client type
     * @param {String} type .
     * @returns {*} .
     */
    const validateClientType = type => {
      let clientTypeError = false
      if (type === null || type === '' || !type) {
        clientTypeError = 'Debe seleccionar un tipo de cliente'
      }
      return clientTypeError
    }

    /**
     * Validate Doc Type and Value
     * @param {String} type .
     * @param {String} value .
     * @returns {*} .
     */
    const validateDoc = (type, value) => {
      let docError = false
      const numerical = [
        'CC',
        'TI',
        'NIT',
        'CEC',
        'CEL',
        'RC'
      ]
      const alphaNumeric = [
        'PP',
        'DE',
        'CE',
        'IDC'
      ]

      const isNumerical = numerical.includes(type)
      const isAlphaNumeric = alphaNumeric.includes(type)
      if (!isNumerical && !isAlphaNumeric) {
        docError = 'Debe escribir una identificación válida'
      }
      if (value === null || value === '' || !value) {
        docError = 'Debe escribir una identificación válida'
      }
      const reAlpha = /^[0-9a-zA-Z]+$/
      const reNumber = /^[0-9]+$/
      if (isNumerical && !reNumber.test(value)) {
        docError = 'Debe escribir una identificación válida'
      } else if (isAlphaNumeric && !reAlpha.test(value)) {
        docError = 'Debe escribir una identificación válida'
      }
      if (type === null || type === '' || !type) {
        docError = 'Debe seleccionar un tipo de identificación'
      }
      return docError
    }

    const bankError = validateBank(bank)
    const clientTypeError = validateClientType(clientType)
    const docError = validateDoc(docType, docValue)
    if (bankError || clientTypeError || docError) {
      return {
        pseError: {
          bankError,
          clientTypeError,
          docError,
        }
      }
    }
    return false
  },
}

export default Validator
