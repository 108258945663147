/* eslint-disable complexity */
/* eslint-disable react/jsx-handler-names */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, createRef } from 'react'
import data from './PaymentSelector1.messages'
import arrow from '../../../Assets/images/svg/arrow_down.svg'
import styles from './PaymentSelector1.module.css'
import visa from '../../../Assets/images/svg/visa.svg'
import mastercard from '../../../Assets/images/svg/mastercard.svg'
import paypal from '../../../Assets/images/svg/paypal.svg'
import unionPay from '../../../Assets/images/svg/unionPay.svg'
import americanExpress from '../../../Assets/images/svg/americanExpress.svg'
import applePay from '../../../Assets/images/png/applePay.png'
import oxxo from '../../../Assets/images/svg/oxxopay.svg'
import spei from '../../../Assets/images/svg/spei.svg'
import pse from '../../../Assets/images/png/pse.png'
import efecty from '../../../Assets/images/png/efecty@2x.png'
import baloto from '../../../Assets/images/png/baloto@2x.png'
import { availablePaymentByCountry } from '../../../Utils/utils'

/**
 * Installments Component
 * @returns {React.Component} Installments .
 */
class PaymentSelector1 extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    this.paymentMethods = data.paymentMethods
    this.state = {
      openDropDown: false,
      currentTopic: this.paymentMethods.filter(method => method.value === props.paymentMethodSelected)[0]
    }

    this.refImg = createRef(null)
  }

  OpenDropDown = () => {
    const { openDropDown } = this.state
    const open = !openDropDown
    this.refImg.current.style.transform = `rotate(${open ? 0 : 180}deg)`
    this.setState({ openDropDown: open })
  }

  /**
   * Change value selector
   * @param {object} item .
   * @param {object} title .
   * @returns {React.Component} Reference .
   */
  changeValue = (item) => {
    this.setState({ currentTopic: item })
    this.props.onChangePayment(item.value)
  }

  /**
   * Select Img
   * @param {string} payment .
   * @returns {React.Component} Reference .
   */
  selectImg = (payment) => {
    switch (payment.type) {
    case 'visa':
      return visa
    case 'mastercard':
      return mastercard
    case 'paypal':
      return paypal
    case 'unionPay':
      return unionPay
    case 'applePay':
      return applePay
    case 'americanExpress':
      return americanExpress
    case 'spei':
      return spei
    case 'oxxo':
      return oxxo
    case 'pse':
      return pse
    case 'efecty':
      return efecty
    case 'baloto':
      return baloto
    default:
      return ''
    }
  }

  /**
   * Render.
   * @param {string} id .
   * @returns {void} .
   */
  render() {
    const { openDropDown, currentTopic } = this.state
    const { country, finalPrice } = this.props
    const hasExpress = data.imgPayments.filter(img => img.express === true && img.enable === true).length
    const listPayments = this.paymentMethods.map(paymentMethod => {
      const idText = `${paymentMethod.value}`
      const name = `${paymentMethod.value}`
      if (paymentMethod.id === 1
          || !availablePaymentByCountry(country, paymentMethod.value, finalPrice)) {
        return null
      }
      if (currentTopic.id !== paymentMethod.id) {
        const item = (
          <li
            key={paymentMethod.id}
            id={idText}
            name={name}
            onClick={() => { this.changeValue(paymentMethod) }}
            className={styles.Items}
            role="menuitem"
          >
            {paymentMethod.text}
          </li>
        )
        return item
      }
      return null
    })
    const selector = (
      <div className={styles.ThemesMobileContainer}>
        <div
          className={styles.TopicSelector}
          onClick={() => this.OpenDropDown()}
          role="button"
          tabIndex="0"
        >
          <div className={styles.TextTema}>
            {currentTopic.text}
            <img
              ref={this.refImg}
              alt="flecha"
              src={arrow}
              style={{ transform: `rotate(${openDropDown ? 0 : 180}deg)` }}
            />
          </div>
          {openDropDown && (
            <ul>
              {listPayments}
            </ul>
          )}
        </div>
      </div>
    )

    const express = (
      <div className={styles.Express}>
        <div className={styles.ExpressImg}>
          {data.imgPayments.map(payment => {
            const image = this.selectImg(payment)
            if (payment.enable && payment.express) {
              return (
                <img
                  key={payment.id}
                  className={styles.ItemExpress}
                  alt="payments"
                  src={image}
                />
              )
            }
            return ''
          })}
        </div>
        <div className={styles.TitleExpress}>{data.titleExpress}</div>
      </div>
    )

    return (
      <div className={styles.PaymentSelector1Container}>
        <div className={styles.PaymentMethosImg}>
          {data.imgPayments.map(payment => {
            const image = this.selectImg(payment)
            let { type } = payment
            const classItem = type
            if (type === 'visa' || type === 'mastercard'
              || type === 'americanExpress') {
              type = 'card'
            }
            if (availablePaymentByCountry(country, type, finalPrice)) {
              return (
                <img
                  className={[styles.ItemPayments, styles[classItem]].join(' ')}
                  key={payment.id}
                  alt="payments"
                  src={image}
                />
              )
            }
            return ''
          })}
          {hasExpress !== 0 && express}
        </div>
        {selector}
      </div>
    )
  }
}

export default PaymentSelector1
