/* eslint-disable complexity */
import React, { Component } from 'react'
import currency from 'currency.js'
import moment from 'moment'
import 'moment/locale/es'
import style from './TimeCounter.module.css'
import messages from './TimeCounter.messages'

/**
 * TimeCounter Component
 * @returns {void} .
 */
class TimeCounter extends Component {
  /**
   * TimeCounter constructor
   * @returns {void} .
   */
  constructor() {
    super()
    moment.locale('es')
    this.state = {
      fortnight: moment()
    }
  }

  /**
   * Component Did Mount
   * @returns {void} .
   */
  componentDidMount() {
    this.chooseFortnight()
  }

  chooseFortnight = () => {
    const currentDate = moment()
    const day = currentDate.date()
    const endOfMonth = moment().endOf('month')

    let fortnight = ''
    if (day <= (15 - 3)) {
      fortnight = currentDate.date(15)
    } else if (day <= (endOfMonth.date() - 3)) {
      fortnight = endOfMonth
    } else {
      fortnight = currentDate.add(endOfMonth.date() - day + 15, 'day')
    }
    this.setState({ fortnight })
  }

  /**
   * TimeCounter render
   * @returns {void} .
   */
  render() {
    const { couponData, currencyProd, externalPromo } = this.props
    const { applyCoupon, currentCoupon, showCoupon } = couponData
    const discountType = currentCoupon && currentCoupon.Discount_Type

    const precision = process.env.REACT_APP_COUNTRY === 'Colombia' ? 0 : 2
    const separator = process.env.REACT_APP_COUNTRY === 'Colombia' ? '.' : ','

    const discountPrice = discountType === 'percentage'
      ? currentCoupon && currentCoupon.Discount
      : `$${currency(currentCoupon ? currentCoupon.Discount : 0, { precision, separator }).format()}`

    /*
     * const { fortnight } = this.state
     * const titleDate = fortnight.format('DD MMMM YYYY')
     */

    let titleCoupon = discountType === 'percentage' ? messages.CouponPercentage : messages.CouponNormal
    titleCoupon = titleCoupon.replace('{discount}', discountPrice).replace('{currency}', currencyProd)

    const changeTitle = showCoupon && !applyCoupon
    let title = changeTitle ? titleCoupon : messages.advice2
    if (externalPromo) {
      title = externalPromo
    }

    return (
      <div className={[style.CounterContainer, changeTitle || externalPromo ? style.Discount : ''].join(' ')}>
        {/* {messages.advice} */}
        {title}
        {/* <strong className={style.Counter}>{title}</strong> */}
      </div>
    )
  }
}

export default TimeCounter
