import React, { Component } from 'react'
import currency from 'currency.js'
import style from './TotalPrice.module.css'
import messages from './TotalPrice.messages'

/**
 * TotalPrice Component
 * @returns {void} .
 */
class TotalPrice extends Component {
  /**
   * TotalPrice constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  /**
   * TotalPrice render
   * @returns {void} .
   */
  render() {
    const { price } = this.props

    const precision = process.env.REACT_APP_COUNTRY === 'Colombia' ? 0 : 2
    const separator = process.env.REACT_APP_COUNTRY === 'Colombia' ? '.' : ','

    const finalPrice = `$${currency(price, { precision, separator }).format()}`
    const priceText = `${messages.total} ${finalPrice}`
    return (
      <div className={style.TotalPrice}>
        <div className={style.PriceContainer}>
          <p className={style.Iva}>{messages.iva}</p>
          <p className={style.Price}>{priceText}</p>
        </div>
      </div>
    )
  }
}

export default TotalPrice
