import React from 'react'
import style from './AddressSaved.module.css'
import messages from './AddressSaved.messages'

/**
 * AddressSaved Component
 * @returns {void} .
 */
const AddressSaved = ({ address }) => {
  const textAddress = address
                   && `${address.street}, ${address.interiorNumber}, ${address.colony}, ${address.zipCode}, ${address.city} `
  return (
    <div className={style.AddressContainer}>
      <p className={style.Title}>{messages.title}</p>
      <p className={style.Address}>{textAddress}</p>
    </div>
  )
}

export default AddressSaved
