import React, { useState, useEffect } from 'react'
import styles from './FormPSE.module.css'
import messages from './FormPSE.messages'
import Selector from './Selector'
import API from '../../Apis/CheckoutBack'

/**
 * FormPSE Component
 * @returns {React.Component} FormPSE .
 */
const FormPSE = ({
  formPSE,
  setPSEData,
  bankError,
  clientTypeError,
  docError
}) => {
  const [banks, setBanks] = useState([])

  const {
    bank, clientType, docType, docValue
  } = formPSE

  useEffect(() => {
    let mounted = true
    if (Array.isArray(banks) && banks.length === 0) {
      API.getAllBankPSE().then(resp => {
        if (mounted) {
          setBanks(resp)
        }
      })
    }
    return () => mounted = false
  })

  /**
   * Change Data
   * @param {String} tag .
   * @param {Object} item .
   * @returns {void} .
   */
  const changeData = (tag, item) => {
    const obj = []
    obj[tag] = item.pseCode
    setPSEData && setPSEData(obj)
  }

  return (
    <div className={styles.PSEContainer}>
      <Selector
        error={bankError}
        defaultValue={bank}
        items={banks}
        changeData={changeData}
        initialValue={messages.initialBanks}
        tag="bank"
      />
      <Selector
        error={clientTypeError}
        defaultValue={clientType}
        items={messages.clientType}
        changeData={changeData}
        tag="clientType"
      />
      <Selector
        error={docError}
        defaultValue={docType}
        defaultInput={docValue}
        items={messages.document}
        changeData={changeData}
        tag="docType"
        tagInput="docValue"
        input
      />
    </div>
  )
}

export default FormPSE
