/* eslint-disable complexity */
import React, { Component, createRef } from 'react'
import styles from './FormPSE.module.css'
import arrow from '../../Assets/images/svg/arrow_down.svg'

/**
 * Selector Component
 * @returns {React.Component} Selector .
 */
class Selector extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    this.state = {
      openDropDown: false,
      currentValue: props.initialValue ? {
        id: 0,
        description: props.initialValue
      } : props.items[0],
    }
    this.refImg = createRef(null)
  }

  /**
   * Component Did Mount
   * @returns {void} .
   */
  componentDidMount() {
    this.defaultData()
  }

  /**
   * Component Did Update
   * @param {Object} prevProps .
   * @param {Object} prevState .
   * @returns {void} .
   */
  componentDidUpdate = (prevProps, prevState) => {
    const { items } = this.props
    if (prevProps.items !== items) {
      this.defaultData()
    }
  }

  OpenDropDown = () => {
    const open = !this.state.openDropDown
    this.refImg.current.style.transform = `rotate(${open ? 0 : 180}deg)`
    this.setState({ openDropDown: open })
  }

  defaultData = () => {
    const { defaultValue, items } = this.props
    items.forEach(element => {
      if (element.pseCode === defaultValue) {
        this.setState({ currentValue: element })
      }
    })
  }

  /**
   * Change value selector
   * @param {object} item .
   * @returns {void} .
   */
  changeValue = (item) => {
    const { changeData, tag } = this.props
    this.setState({ currentValue: item })
    changeData && changeData(tag, item)
  }

  /**
   * Handle Change Input
   * @param {object} event .
   * @returns {void} .
   */
  handleChangeInput = (event) => {
    const { changeData, tagInput } = this.props
    const re = /^[0-9a-zA-Z]+$/
    if (event.target.value === '' || re.test(event.target.value)) {
      changeData && changeData(tagInput, { pseCode: event.target.value })
    }
  }

  /**
   * Render.
   * @param {string} id .
   * @returns {void} .
   */
  render() {
    const { openDropDown, currentValue } = this.state
    const {
      items, input, defaultInput, error
    } = this.props

    const classContainer = input && currentValue.id !== 0
                         && !openDropDown
      ? styles.InputContainer : ''
    return (
      <div className={styles.SelectorContainer}>
        <div className={styles.SelectorWrapper}>
          <div
            className={[styles.TopicSelector, classContainer].join(' ')}
            onClick={() => this.OpenDropDown()}
            role="button"
            tabIndex="0"
          >
            <div className={styles.TextTema}>
              {input && currentValue.id !== 0 ? currentValue.pseCode : currentValue.description}
              <img
                ref={this.refImg}
                alt="flecha"
                src={arrow}
                style={{ transform: `rotate(${openDropDown ? 0 : 180}deg)` }}
              />
            </div>
            {openDropDown && (
              <ul className={styles.Scroll}>
                {Array.isArray(items) && items.map((item, index) => (
                  <li
                    key={item.id}
                    className={styles.Items}
                    onClick={() => this.changeValue(item)}
                    role="menuitem"
                  >
                    {input ? `${item.code || ''} ${item.description}` : item.description}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {(input && !openDropDown && currentValue.id !== 0) && (
            <input
              className={styles.Input}
              type="text"
              value={defaultInput}
              placeholder="&nbsp;"
              onChange={this.handleChangeInput}
            />
          )}
        </div>
        {error && (<div className={styles.Error}>{error}</div>)}
      </div>
    )
  }
}

export default Selector
