import React from 'react'
import styles from './PersonalData.module.css'
import FormGroup from '../FormGroup/FormGroup'

/**
 * PersonalData Component
 * @param {*} props - .
 * @param {boolean}   errorName - true if error in name input
 * @param {boolean}   errorEmail - true if error in email input
 * @param {boolean}   errorNumber - true if error in number input
 * @param {String}    nameTextLabel - text for label name input
 * @param {String}    emailTextLabel - text for email label input
 * @param {String}    numberTextLabel - text for number label input
 * @returns {void} .
 */
const PersonalData = (props) => {
  const {
    errorName,
    errorEmail,
    errorNumber,
    nameTextLabel,
    emailTextLabel,
    numberTextLabel,
    onChangeValue,
    personalData
  } = props

  return (
    <div className={styles.PersonalDataContainer}>
      <FormGroup
        idInput="name"
        error={errorName}
        typeInput="text"
        textLabel={nameTextLabel}
        defaultValue={personalData.name}
        onChange={(event) => onChangeValue('personalData', 'name', event.target.value)}
      />
      <FormGroup
        idInput="email"
        error={errorEmail}
        typeInput="email"
        textLabel={emailTextLabel}
        defaultValue={personalData.email}
        onChange={(event) => onChangeValue('personalData', 'email', event.target.value)}
      />
      <FormGroup
        idInput="number"
        error={errorNumber}
        typeInput="number"
        textLabel={numberTextLabel}
        defaultValue={personalData.number}
        onChange={(event) => onChangeValue('personalData', 'number', event.target.value)}
      />
    </div>
  )
}

export default PersonalData
