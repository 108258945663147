import React from 'react'
import ReactToPrint from 'react-to-print'
import styles from './SpeiTemplate.module.css'
import messages from './SpeiTemplate.messages'
import SuccessAddress from '../SuccessAddress/SuccessAddress'

/**
 * Spei Component
 * @returns {React.Component} SpeiTemplate .
 */
const SpeiTemplate = ({
  reference, clabe, bank, refContainer,
  category, address, onChangeValue, customerId, paymentMethodSelected
}) => {

  return (
    <div className={styles.SpeiContainer}>
      {reference
      && (
        <>
          <p className={styles.SpeiReference}>{messages.title}</p>
          <div className={styles.RefContainer}>
            {reference}
          </div>
        </>
      )}

      <p className={styles.SpeiTitle}>{messages.subTitle}</p>

      <div className={styles.SpeiData}>
        <p className={styles.SpeiItem}>
          <b>{messages.clabe}</b>
          {clabe}
        </p>
        <p className={styles.SpeiItem}>
          <b>{messages.bank}</b>
          {bank}
        </p>
        <p className={styles.SpeiItem}>
          <b>{messages.beneficiary}</b>
          {messages.beneficiaryName}
        </p>
      </div>

      <p className={styles.Notice}>
        {messages.notice}
      </p>

      <div className={styles.Buttons}>
        <ReactToPrint
          trigger={() => (
            <div className={styles.ButtonDownload}>
              {messages.textDownload}
            </div>
          )}
          content={() => refContainer.current}
        />
      </div>
      <SuccessAddress
        category={category}
        address={address}
        onChangeValue={onChangeValue}
        customerId={customerId}
        paymentMethodSelected={paymentMethodSelected}
      />
    </div>
  )
}

export default SpeiTemplate
